import React from "react";
import partnerDetailStyles from "./PartnerDetail.module.css";

const PartnerClientItem = ({title, content}) => {
    return <>
        <div className={partnerDetailStyles["nintex-solution-client-heading"]}>
            <h5>{title}</h5>
            {title.toLowerCase() === "email" && <a href={`mailto:${content}`}>{content}</a>}
            {title.toLowerCase() === "phone" && <a href={`tel:${content}`}>{content}</a>}
            {title.toLowerCase() === "website" && <a href={content}>{content}</a>}
            {(title.toLowerCase() !== "website" && title.toLowerCase() !== "phone" && title.toLowerCase() !== "email") && <a href={content}>{content}</a>}
        </div>
    </>
};

export default PartnerClientItem;