import React from "react";
import sectionalLoadingStyles  from "./SectionalLoading.module.css";
const SectionalLoading = (props) => {
    // return <div className={loadingStyles["loading"]}>
    //     <img src={loading} height={props.height} width={props.width} />
    // </div>
    return <div className={sectionalLoadingStyles["loading"]}>
        <div className={sectionalLoadingStyles.container}>
            <div className={sectionalLoadingStyles.item + " " + sectionalLoadingStyles["item-1"]}></div>
            <div className={sectionalLoadingStyles.item + " " + sectionalLoadingStyles["item-3"]}></div>
        </div>
    </div>
};

export default SectionalLoading;