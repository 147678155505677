import { MONTH_MAPPING } from "../constants";

/**
 * @author Varun Kashyap
 * @param {*} error 
 * @param {*} errorList 
 * @returns true or false
 * @description This checks if error is already present in the error list or not.
*/
export const checkError = (error, errorList) => {    
    errorList = errorList.filter(errors => (errors.action === error));
    return errorList.length > 0 ? true : false;
};

/**
 * 
 * @param {*} value 
 * @returns true / false
 * @description Checks if value is empty or not.
 */
export const isEmpty = value => value.trim().length === 0;

export const sort = (prev, next) => {
    if ( prev.field_index < next.field_index ){
        return -1;
    }
    if ( prev.field_index > next.field_index ){
        return 1;
    }
    return 0;
}

export const getDateTime = (date) => {
    var datetime = date ? new Date(date) : new Date();
    datetime = datetime.getFullYear()
        + "-"
        + ('0' + (datetime.getMonth() + 1)).slice(-2)
        + "-"
        + ('0' + datetime.getDate()).slice(-2) + " "
        + ('0' + datetime.getHours()).slice(-2) + ":"
        + ('0' + datetime.getMinutes()).slice(-2) + ":"
        + ('0' + datetime.getSeconds()).slice(-2);
    return datetime;
}

export const capitalize = (keyword) => {
    return keyword.charAt(0).toUpperCase() + keyword.substr(1, keyword.length);    
}

export const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <b>{part}</b> : part)}</span>;
}

export const humanDate = (date) => {
    date = date.split("-");
    const day = date[2];
    const month = MONTH_MAPPING[parseInt(date[1])];
    const year = date[0];
    return month + " " + day + ", " + year;
}

export const getQueryParams = (query) => {
    const searchParams = {};
    if(query) {
        query = query.split("?")[1];
        query = query.split("&");
        for(const q of query) {
            const entries = q.split("=");
            const key = entries[0];
            const value = entries[1];
            searchParams[key] = value;
        }
    }
    return searchParams;
}

export const generateQuery = (query) => {
    if(Object.keys(query).length > 0) {
        let queryStr = "?";
        for(const q in  query) {
            queryStr += `${q}=${query[q]}&`
        }
        return queryStr.substring(0, (queryStr.length - 1));
    } else {
        return query;
    }
}

export const getExtension = (url) => {
    return url.split('.').pop()?.toLowerCase();
}

export const currentURL = () => {
    return window.location.protocol+"//"+window.location.host;
}