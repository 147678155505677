/**
 * @author Varun Kashyap
 */
import React from "react";
import inputFieldStyles from "./InputField.module.css";

/**
 * @description Generic input component for Gallery App
 */
const InputField = ({type, className, children, id, defaultValue}) => {
    return <input id={id} type={type} className={inputFieldStyles["input"] + " " + className} defaultValue={defaultValue}>{children}</input>
};

export default InputField;