// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_container__EZpcz {\n    max-width: 1200px;\n    width: 100%;\n    margin-right: auto;\n    margin-left: auto;\n    padding: 0 10px;\n    display: flex;\n    justify-content: space-between;\n}\n.Layout_nintex-layout__1cXXD {}", "",{"version":3,"sources":["webpack://./src/components/Core/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,8BAA8B;AAClC;AACA,8BAAgB","sourcesContent":[".container {\n    max-width: 1200px;\n    width: 100%;\n    margin-right: auto;\n    margin-left: auto;\n    padding: 0 10px;\n    display: flex;\n    justify-content: space-between;\n}\n.nintex-layout {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Layout_container__EZpcz",
	"nintex-layout": "Layout_nintex-layout__1cXXD"
};
export default ___CSS_LOADER_EXPORT___;
