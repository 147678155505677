export const CAPABILITY_CARDS = [
    {
        title: "Process Maps",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "process-maps-1.png"
    },
    {
        title: "Workflows",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "workflows-2.png"
    },
    {
        title: "RPA Botflows",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "rpa-3.png"
    },
    {
        title: "Forms",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "forms-4.png"
    },
    {
        title: "Connectors",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "connector-5.png"
    },
    {
        title: "Nintex K2 Cloud",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "k2-6.png"
    },
    {
        title: "Tools",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "tools-7.png"
    },
    {
        title: "Template Sets",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        image: "template-8.png"
    }
];

export const FILTER_TEMPLATES = [
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        isFeatured: true,
        image: "process-maps-1.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        image: "workflows-2.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        image: "rpa-3.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        isFeatured: true,
        image: "forms-4.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        image: "connector-5.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        image: "k2-6.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        image: "tools-7.png"
    },
    {
        title: "Title goes here and can be three lines long Title goes here and can be",
        description: "Attention to the implement Risk Responses process will ensure that agreed-upon risk...",
        createdBy: "Partner/Name",
        status: "Released",
        createdDate: "Nov 11, 2021",
        language: "English",
        capability: "Process Maps",
        likes: 2,
        dlCounter: 2,
        image: "template-8.png"
    },
];

export const BROWSE_CAPABILITY_HEADING = "Browse By Capability";
export const BROWSE_PARTNER_HEADING = "Browse By Partner";
export const VIEW_TEMPLATES = "View templates";

export const FEATURED_LABEL = "Featured";
export const CREATED_BY_TEXT = "Published by";

export const FILTER_TEMPLATES_TITLE = "Trending";

export const FILTERS = [
    {
        id: "featured",
        label: "FEATURED",
        active: true
    },
    {
        id: "recent",
        label: "RECENT"
    },
    {
        id: "popular",
        label: "POPULAR"
    },
    {
        id: "partner",
        label: "PARTNER"
    }
];