import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { apiHandler } from "../../../helpers/apiHelper";
import { responseHelper } from "../../../helpers/responseHelper";
import Loading from "../../UI/Loading/Loading";
import Card from "../../UI/Card/Card";
import partnerDetailStyles from "./PartnerDetail.module.css";
import PartnerClientItem from "./PartnerClientItem";
import { PARTNER_RELATED_NP_FOUND, PARTNER_RELATED_NT_FOUND, PARTNER_RELATED_TEMPLATE_TITLE } from "../../../constants/index";

const PartnerDetail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const {partnerId} = params;
    const uiSelector = useSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState(true);
    const [partnerDetail, setPartnerDetail] = useState([]);
    const [partnerTemplates, setPartnerTemplates] = useState([]);
    useEffect(() => {
        getPartner();
        getRelatedTemplates();
    }, [partnerId, uiSelector.url]);

    const getPartner = () => {
        apiHandler(`${uiSelector.url}/partners/v1/findByURL/${partnerId}`, "GET", null, null).then(partnerResponse => {
            if(partnerResponse.statusCode === responseHelper[200].statusCode) {
                setPartnerDetail(partnerResponse.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(partnerError => {
            setIsLoading(false);
        });
    }

    const getRelatedTemplates = () => {
        apiHandler(`${uiSelector.url}/partners/v1/relatedTemplates/${partnerId}`, "GET", null, null).then(partnerTemplatesResponse => {
            if(partnerTemplatesResponse.statusCode === responseHelper[200].statusCode) {
                setPartnerTemplates(partnerTemplatesResponse.data);
            }
        });
    }

    const templateDetailHandler = (e) => {
        let ele = e.target;
        while (ele) {
            ele = ele.parentNode;
            if(ele.id) break;
        }
        if(ele.id && ele.id !== "root" && ele.id !== "") {
            navigate(`/t/${ele.id}`);
        }
    }

    return(
        <>
        {(!isLoading && partnerDetail.length > 0) && <><section className={partnerDetailStyles["nintex-solution-partner-page"]}>
            <h1>{partnerDetail[0].name}</h1>
        </section>
        <section className={partnerDetailStyles["nintex-solution-partner"]}>
            <div className={partnerDetailStyles["container"]}>
                <div className={partnerDetailStyles["nintex-solution-client-main-content"]}>
                    <div className={partnerDetailStyles["nintex-solution-clients"]}>
                        <div className={partnerDetailStyles["nintex-solution-client-logo"]}>
                            <img src={partnerDetail[0].large_icon} alt="partner-large-icon" />
                        </div>
                        <div className={partnerDetailStyles["nintex-solution-client-details"]}>
                            <PartnerClientItem title="WEBSITE" content={partnerDetail[0].site_url} />
                            <PartnerClientItem title="PHONE" content={partnerDetail[0].phone_no} />
                            <PartnerClientItem title="EMAIL" content={partnerDetail[0].email} />
                        </div>
                    </div>
                    <div className={partnerDetailStyles["nintex-solution-client-rightside-content"]} dangerouslySetInnerHTML={{__html: partnerDetail[0].description}}></div>
                </div>
            </div>
        </section>
        <section>
            <div className={partnerDetailStyles["container"]}>
                <div className={partnerDetailStyles["nintex-solution-related-templates"]}>
                    <h1>{PARTNER_RELATED_TEMPLATE_TITLE}</h1>
                    <div className={partnerDetailStyles["nintex-solution-cards"]} onClick={templateDetailHandler}>
                        {partnerTemplates.length > 0 && partnerTemplates.map((pt, key) => (
                            <Card id={pt._id} friendlyURL={pt.friendly_url} key={key} className="pointer" featured={pt.is_featured} title={pt.title} image={pt.icon_image} language={pt.language} status={pt.status} createdDate={pt.created_on} createdBy={pt.partner} likes={pt.likes} dlCounter={pt.dl_counter} capability={pt.capability} showDescription={false} />
                        ))}
                        {partnerTemplates.length === 0 && <p>{PARTNER_RELATED_NT_FOUND}</p>}
                    </div>
                </div>
            </div>
        </section>
        </>}
        {(!isLoading && partnerDetail.length === 0) && <section className={partnerDetailStyles["nintex-solution-partner-page"]}>
            <h1>{PARTNER_RELATED_NP_FOUND}</h1>
        </section>}
        {isLoading && <div className="text-center"><Loading /></div>}
    </>
    );
};

export default PartnerDetail;