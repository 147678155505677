export const THEME = {
    header: {
        div: {
            bg: "#003870",
            color: "#FFFFFF"
        },
        image: {
            searchIcon: {
                bg: "#296CCF",
                color: ""
            }
        },
        li: {
            bg: "",
            color: "#FFFFFF"
        },
        button: {
            bg: "#003870",
            color: "#FFFFFF"
        }
    },
    home: {
        div: {
            bg: "#003870",
            color: "#FFFFFF"
        },
        image: {
            searchIcon: {
                bg: "#296CCF",
                color: ""
            }
        },
        li: {
            bg: "",
            color: "#FFFFFF"
        },
        button: {
            bg: "#003870",
            color: "#FFFFFF"
        }
    },
    search: {
        div: {
            bg: "#003870",
            color: "#FFFFFF"
        },
        image: {
            searchIcon: {
                bg: "#296CCF",
                color: ""
            }
        },
        li: {
            bg: "",
            color: "#FFFFFF"
        },
        button: {
            bg: "#F0F3F8",
            color: "#000000",
            border: "#F0F3F8"
        }
    },
    footer: {
        div: {
            bg: "#003870",
            color: "#FFFFFF"
        },
        searchIcon: {
            bg: "",
            color: ""
        },
        li: {
            bg: "",
            color: "#FFFFFF"
        },
        a: {
            color: "#FFFFFF"
        },
        button: {
            bg: "#003870",
            color: "#FFFFFF"
        }
    },
}