/**
 * @author Varun Kashyap
*/
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import fileDownload from "js-file-download";
import Loading from "../../UI/Loading/Loading";
import { TEMPLATE_DETAIL_GROUPS } from "../../../constants/templateDetail";
import Group from "../../UI/Group/Group";
import ShareTemplate from "../../UI/ShareTemplates/ShareTemplate";
import Disclaimer from "../../UI/Disclaimer/Disclaimer";
import ImportTenantModal from "../../UI/ImportTenantModal/ImportTenantModal";
import { apiHandler } from "../../../helpers/apiHelper";
import { responseHelper } from "../../../helpers/responseHelper";
import { NO_TEMPLATE_DETAIL_RESULTS } from "../../../constants";
import downloadIcon from "../../../assets/images/Download Icon Small White.svg";
import shareIcon from "../../../assets/images/Share Icon Small.svg";
import templateDetailStyles from "./TemplateDetail.module.css";
import { getExtension } from "../../../helpers";
/**
 * 
 * @returns Template Detail component for public gallery
*/
const TemplateDetail = () => {
    const params = useParams();
    const uiSelector = useSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState(true);
    const [importTenantModal, setImportTenantModal] = useState(false);
    const [templateDetail, setTemplateDetail] = useState([]);
    const [canShareTemplates, setCanShareTemplates] = useState(false);
    const {templateId} = params;

    const downloadTemplateFile = () => {
        const downloadUrl = templateDetail?.[0].download_url;
        const tID = templateDetail?.[0].templateId;
        const dlCounter = templateDetail?.[0].dl_counter;
        let newCount = dlCounter + 1;
        if(downloadUrl) {
            const extension = getExtension(downloadUrl);

            if(extension !== "zip") {

                apiHandler(downloadUrl, "GET", null, null).then(templateDownloadContent => {
                    
                    fileDownload(templateDownloadContent, `${templateDetail?.[0].title}.${extension}`);
                    
                    //we use increment instead of edit because we want to keep download stats
                    apiHandler(`${uiSelector.url}/templates/v1/increment/${tID}`, "PUT", {
                        dl_counter: newCount
                    }, null);
                    
                }).catch(templateDownloadError => {
                    // console.log(templateDownloadError);
                });

            } else {
         
                apiHandler(
                    downloadUrl, 
                    "GET", 
                    null, 
                    {'Content-Type': 'application/zip',},
                    'arraybuffer'
                    ).then(templateDownloadContent => {
                    
                    fileDownload(templateDownloadContent, `${templateDetail?.[0].title}.${extension}`);
                    
                    //we use increment instead of edit because we want to keep download stats
                    apiHandler(`${uiSelector.url}/templates/v1/increment/${tID}`, "PUT", {
                      dl_counter: newCount
                    }, null);
                    
                }).catch(templateDownloadError => {
                    // console.log(templateDownloadError);
                });
            }//endif extension
        }//endif downloadurl
    }//end funcrtion downloadTemplateFile
    
    useEffect(() => {
        if(!templateId && templateId === 0) {
            setIsLoading(false);
            return;
        }

        // This useEffect loads (or reloads) template data. 
        apiHandler(`${uiSelector.url}/templates/v1/findByUrl/${templateId}`, "GET", null, null).then(templateDetail => {
            if(templateDetail.statusCode === responseHelper[200].statusCode) {
                setTemplateDetail(templateDetail.data);
                if(document.querySelector("[name='capability']")) document.querySelector("[name='capability']").remove();
                if(document.querySelector("[name='capability_id']")) document.querySelector("[name='capability_id']").remove();

                document.head.innerHTML += `<meta name='capability' content='${templateDetail.data[0]?.capability}'>`;
                document.head.innerHTML += `<meta name='capability_id' content='${templateDetail.data[0]?.capability_id}'>`;
                if(document.getElementsByTagName("title").length > 0) document.getElementsByTagName("title")[0].innerText = `Nintex Gallery - ${templateDetail.data[0]?.title}`;
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(templateDetailError => {
            setIsLoading(false);
        });
return ()=> {
    document.querySelector("[name='capability']").remove();
    document.querySelector("[name='capability_id']").remove();
    document.getElementsByTagName("title")[0].innerText = `Nintex Gallery`;
}
}, [templateId, importTenantModal, uiSelector.url]);

    const importTenant = (...args) => {
        const [nwcToken, title, templateId, dlCounter] = args;
        setImportTenantModal(true);
    }//end function importTenant

    return <>
        {(!isLoading && templateDetail.length > 0) && templateDetail.map((td, key) => (
            <React.Fragment key={key}>
                <section className={templateDetailStyles["nintex-solution-template-detail"]}>
                    <div className={templateDetailStyles.container}>
                        <img src={td.icon_image} alt="connector-logo" />
                        <h1>{td.title}</h1>                        
                    </div>
                </section>
                <section className={templateDetailStyles["nintex-solution-template-detail-icons"]}>
                    <div className={templateDetailStyles.container}>
                        <h4 className={templateDetailStyles["nintex-solution-template-detail-capability"] + " " + 'adsearch_' + td.capability_id}>{td.capability}</h4>
                        {((td.download_url !== "" && td.download_url !== "NA") || (td.nwc_token && td.nwc_token !== "undefined")) && <div className={templateDetailStyles["nintex-solution-template-detail-buttons"]}>
                            {(td.nwc_token && td.nwc_token !== "undefined") && <button className={templateDetailStyles["nintex-solution-button-download"]} onClick={() => importTenant(td.nwc_token, encodeURI(td.title), td._id, td.dl_counter)} id="download-button">
                                <img src={downloadIcon} alt="download-button" />
                                {td.capability.toLowerCase() === "workflows" && "Import"}
                                {td.capability.toLowerCase() !== "workflows" && "Download"}
                            </button>}
                            {(!td.nwc_token || td.nwc_token === "undefined") && <button className={templateDetailStyles["nintex-solution-button-download"]} onClick={() => downloadTemplateFile()} id="download-button">
                                <img src={downloadIcon} alt="download-button" />
                                {td.capability.toLowerCase() === "workflows" && "Import"}
                                {td.capability.toLowerCase() !== "workflows" && "Download"}
                            </button>}                            
                            <button className={templateDetailStyles["nintex-solution-button-share"]} alt="share-button" id="share-button" onClick={() => setCanShareTemplates(!canShareTemplates)}>
                                <img src={shareIcon} alt="share-icon" />
                                Share
                            </button>
                            <ImportTenantModal templateDetail={templateDetail} importTenantModal={importTenantModal} clickHandler={(state) => setImportTenantModal(state)} />
                            <ShareTemplate canShareTemplates={canShareTemplates} clickHandler={(state) => setCanShareTemplates(state)} templateDetail={templateDetail} />
                        </div>}
                        {/* {<div className={templateDetailStyles["nintex-solution-template-detail-buttons"]}>
                            <p>Allow us a moment, we are verifying your tenant details...</p>
                        </div>} */}
                        <div className={templateDetailStyles["nintex-solution-template-detail-icons-section"]}>
                            {TEMPLATE_DETAIL_GROUPS.map((tdg, tdgkey) => (
                                <React.Fragment key={tdgkey}>
                                    {(td.partner && tdg.title.toLowerCase() === "publisher") && <Group contentClassName="pointer" partner={td.partner} partnerId={td.partnerId} title={tdg.title} content={td.partner || "Nintex"} image={tdg.image} />}
                                    {(!td.partner && tdg.title.toLowerCase() === "publisher") && <Group title={tdg.title} content="Nintex" image={tdg.image} />}

                                    {tdg.title.toLowerCase() === "downloads" && <Group className="nintex-solution-downloads" title={tdg.title} content={td.dl_counter} image={tdg.image} />}
                                    {tdg.title.toLowerCase() === "department" && <Group title={tdg.title} content={td.department_name} image={tdg.image} />}
                                    {tdg.title.toLowerCase() === "language" && <Group title={tdg.title} content={td.language.toUpperCase()} subContent={td.fullLanguage} image={tdg.image} />}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </section>
                <section className={templateDetailStyles["nintex-solution-template-detail-body"]}>
                    <div className={templateDetailStyles.container}>
                        <div dangerouslySetInnerHTML={{__html: td.content}}></div>
                    </div>
                </section>
                <Disclaimer />  
            </React.Fragment>
        ))}
        {(!isLoading && templateDetail.length === 0) && <div className="emoty-content">{NO_TEMPLATE_DETAIL_RESULTS}</div> }
        {isLoading && <div className="text-center"><Loading /></div>}
    </>
};

export default TemplateDetail;