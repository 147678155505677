/**
 * @author Varun Kashyap
*/
import React from "react";
import { useSelector } from "react-redux";
import footerStyles from "./Footer.module.css";
import layoutStyles from "../../Core/Layout/Layout.module.css";
import Div from "../Div/Div";
import ListItem from "../ListItem/ListItem";
import { FOOTER_COPYRIGHT, FOOTER_ITEMS } from "../../../constants/footer";

/**
 * 
 * @returns Footer component for public gallery
*/
const Footer = () => {
    const uiSelector = useSelector(state => state.ui);
    const { theme } = uiSelector;
    return <Div component="footer" theme={theme} className={footerStyles["nintex-footer"]}>
        <div className={layoutStyles.container}>
            <div className={footerStyles["nintex-footer-menu"]}>
                <ul>
                    {FOOTER_ITEMS.map(ft => (
                        <ListItem key={ft.name}component="footer" theme={theme}><a href={ft.link} target="_blank" rel="noreferrer">{ft.name}</a></ListItem>
                    ))}
                </ul>
                <small>{FOOTER_COPYRIGHT}</small>
            </div>
        </div>
    </Div>
};

export default Footer;