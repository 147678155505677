/**
 * @author Varun Kashyap
*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { apiHandler } from "../../../helpers/apiHelper";
import { responseHelper } from "../../../helpers/responseHelper";
import Footer from "../../UI/Footer/Footer";
import Header from "../../UI/Header/Header";
import Home from "../Home/Home";
import NotFound from "../NotFound/NotFound";
import Search from "../Search/Search";
import TemplateDetail from "../TemplateDetail/TemplateDetail";
import layoutStyles from "./Layout.module.css";
import PartnerDetail from "../PartnerDetail/PartnerDetail"
import TemplateSet from "../TemplateSet/TemplateSet";
import { getQueryParams } from "../../../helpers";

/**
 * 
 * @returns Main layout component for public gallery
*/
const Layout = () => {
    const uiSelector = useSelector(state => state.ui);
    const [capabilities, setCapabilities] = useState([]);
    const [partners, setPartners] = useState([]);
    const [capabilityLoading, setCapabilityLoading] = useState(true);
    const location = useLocation();
    let query = location.search;
    query = getQueryParams(query);
    const [search, setSearch] = useState(query || {});
    useEffect(() => {
        //console.log(document.referrer);
        apiHandler(uiSelector.url+"/capabilities/v1/find", "GET", null, null).then(capabilityResponse => {
            if(capabilityResponse.statusCode === responseHelper[200].statusCode) {
                setCapabilities(capabilityResponse.data);
                setCapabilityLoading(false);

            }
        }).catch(capabilityError => {
            setCapabilities([]);
            setCapabilityLoading(false);
        });
        apiHandler(uiSelector.url+"/partners/v1/find", "GET", null, null).then(partnerResponse => {
            if(partnerResponse.statusCode === responseHelper[200].statusCode) {
                setPartners(partnerResponse.data);
                // setpartnerLoading(false);

            }
        }).catch(partnerError => {
            setPartners([]);
            // setpartnerLoading(false);
        });
        // Promise.allSettled([capbilities]).then(allSettled => {
        //     const capabilities = allSettled[0];
        //     if(capabilities.status === "fulfilled") {
        //         if(capabilities.value.statusCode === responseHelper[200].statusCode) {
        //             setCapabilities(capabilities.value.data);
        //         }
        //     }
        // });
    }, [uiSelector.url]);

    const searchHandler = (search) => {
        setSearch(search);
    }

    return <div className={layoutStyles["nintex-layout"]}>
        <Header capabilities={capabilities} searchHandler={searchHandler} />
            <Routes>
                <Route path="/" element={<Home capabilities={capabilities} partners={partners} capabilityLoading={capabilityLoading} />} />
                <Route path="/search" element={<Search searchTerm={search} />} />
                <Route path="/t/:templateId" exact element={<TemplateDetail />} />
                <Route path="/p/:partnerId" exact element={<PartnerDetail />} />
                <Route path = "/ts/:templateSetId" exact element ={<TemplateSet />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        <Footer />
    </div>
};

export default Layout;