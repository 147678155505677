/**
 * @author Varun Kashyap
 */
import React from "react";
import styled from "styled-components";
/**
 * @returns Return button component for public gallery.
 */
const BUTTON = styled.button`
    color: ${props => props.theme[props.component]?.button?.color || "#000000"};
    border: 1px solid ${props => props.theme[props.component]?.button?.border || "#000000"};
`;
const Button = ({id, className, onClick, theme, dataTarget, children, component, type, disabled}) => {
    return <BUTTON type={type} id={id} component={component} onClick={onClick ? onClick : null} theme={theme} className={className} dataTarget={dataTarget} disabled={disabled}>{children}</BUTTON>
};

export default Button;