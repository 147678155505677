export const GLOBAL_ACTIONS = {
    SETSEARCH: "Set Search"
}
export const NO_TEXT = "No"
export const NO_TEMPLATE_RESULTS = "No Templates Found.";
export const NO_CAPABILITY_RESULTS = "No Capabilities Found.";
export const NO_TEMPLATE_DETAIL_RESULTS = "No Template Found.";
export const MONTH_MAPPING = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}
export const PARTNER_RELATED_TEMPLATE_TITLE = "Templates";
export const PARTNER_RELATED_NT_FOUND = "No related templates found.";
export const PARTNER_RELATED_NP_FOUND= "No partner found.";
export const NO_PARTNER_RESULTS= "No Partners found.";