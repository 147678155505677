/**
 * @author Varun Kashyap
*/
import React from "react";
import styled from "styled-components";
/**
 * Dynamic theme branding
*/
const LI = styled.li`
    color: ${props => props.theme[props.component]?.li?.color || "#FFFFFF"}
`;
/**
 * 
 * @param {*} props 
 * @returns ListItem component for public gallery
 */
const ListItem = ({children, component, theme, className, onClick}) => {
    return <LI component={component} theme={theme} className={className || null} onClick={onClick || null}>{children}</LI>
};

export default ListItem;