/**
 * @author Varun Kashyap
*/
import React from "react";
import loadMoreStyles from "./LoadMore.module.css";

/**
 * 
 * @returns Load More component for public gallery
*/
const LoadMore = ({clickHandler}) => {
    return <div className={loadMoreStyles["nintex-solution-add-button"]} onClick={clickHandler}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
            viewBox="0 0 24 24" stroke="#116ED2">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    </div>
};

export default LoadMore;