export const TEMPLATE_DETAIL_GROUPS = [
    {
        title: "Publisher",
        image: "PublisherIcon.svg"
    },
    {
        title: "Downloads",
        image: "Group 1765.svg"
    },
    {
        title: "Department",
        image: "DepartmentIcon.svg"
    },
    {
        title: "Language",
        image: "LanguageIcon.svg"
    }
];
