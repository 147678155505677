/**
 * @author Varun Kashyap
*/
import React from "react";
import styled from "styled-components";
/**
 * Dynamic theme branding
*/
const DIV = styled.div`
    background-color: ${props => props.theme[props.component]?.div?.bg || "#FFFFFF"};
    color: ${props => props.theme[props.component]?.div?.color || "#FFFFFF"};
`;
/**
 * 
 * @param {*} props 
 * @returns Div component for public gallery
 */
const Div = ({children, component, theme, className}) => {
    return <DIV component={component} theme={theme} className={className || null}>{children}</DIV>
};

export default Div;