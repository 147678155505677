/**
 * @author Varun Kashyap
*/
import React from "react";
import { CREATED_BY_TEXT, FEATURED_LABEL, VIEW_TEMPLATES } from "../../../constants/home";
import { humanDate } from "../../../helpers";
import {CARD_PUBLISHED_STATUS} from "../../../constants/cards";
import cardStyles from "./PartnerCard.module.css";
import { useNavigate } from "react-router-dom";

/**
 * 
 * @returns Card component for public gallery
*/
const PartnerCard = ({id, featured, title, friendlyURL, description, image, language, status, createdDate, createdBy, likes, dlCounter, capability, showDescription, className, type}) => {
    const navigate = useNavigate();
    return <div className={className + " " + cardStyles["nintex-solution-card"]} id={type === "partner" ? id : friendlyURL}>
        <div className={cardStyles["nintex-solution-card-heading"]}>
        {featured === 1 && <div className={cardStyles["nintex-solution-featured"]}>{FEATURED_LABEL}</div>}
            {title.length > 49 && <h3 className={(showDescription ? cardStyles["nintex-solution-browse-cards"] : cardStyles["nintex-solution-partner-cards"] + " " + className)}>{title.substr(0, 50) + "..."}</h3>}
            {title.length < 50 && <h3 className={(showDescription ? cardStyles["nintex-solution-browse-cards"] : cardStyles["nintex-solution-partner-cards"] + " " + className)}>{title}</h3>}
           
            {(showDescription && description.length > 89) && <div dangerouslySetInnerHTML={{__html: description.substr(0, 90) + "..."}} />}
            {(showDescription && description.length < 90) && <div dangerouslySetInnerHTML={{__html: description}} />}
            {!showDescription && type !== "partner" && <><p className={cardStyles["nintex-solution-partner-content"]}>{CREATED_BY_TEXT} {createdBy || 'Nintex'} </p>
            {/* <p className={cardStyles["nintex-solution-partner-content"]}>{language?.toUpperCase()} | {status || ""} {createdDate || ""}</p></>} */}
            {type !== "partner" && <p className={cardStyles["nintex-solution-partner-content"]}>{CARD_PUBLISHED_STATUS.released || ""} {humanDate(createdDate.split(" ")[0]) || ""}</p>}</>}
        </div>
        <div className={cardStyles["nintex-solution-card-footer"]}>
            <div className="pointer" onClick={()=>{navigate(`/p/${id}`);}}>Show More  &#8250;&#8250;</div>
            <img src={`${image}`} alt={title.split(" ")?.[0]} />
        </div>
    </div>
};  

export default PartnerCard;