import React, { useEffect } from "react";
import Layout from "./components/Core/Layout/Layout";
import './App.css';

const {REACT_APP_ANALYTICS_URL, REACT_APP_ANALYTICS_ID} = process.env

function App() {
  // Adding the Umami script caller to the header of the app
  // Environment variables hold the URL to the umami server, and the
  // tracking code DI for the congifured web site within.
  useEffect(() => {
    const script = document.createElement("script");
    script.src = REACT_APP_ANALYTICS_URL;
    script.async = true;
    script.defer = true;
    script.dataset.websiteId = REACT_APP_ANALYTICS_ID;
    document.head.appendChild(script);
  }, []);
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
