/**
 * @author Varun Kashyap
*/
import React, { useState } from "react"
import { useSelector } from "react-redux";
import { apiHandler } from "../../../helpers/apiHelper";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import importTenantStyles from "./ImportTenantModal.module.css";
import { IMPORT_INSTRUCTIONS, IMPORT_INVALID } from "../../../constants/ImportTenantModal.js";

/**
 * @description Import tenant modal component for galley app.
 */
const ImportTenantModal = ({templateDetail, importTenantModal, clickHandler}) => {
  const uiSelector = useSelector(state => state.ui);
  const [isValidatingTenant, setIsValidatingTenant] = useState(false);
  const [validatingTenantState, setValidatingTenantState] = useState(true);
  const [partialTemplate, setPartialTemplate] = useState({
    nwcToken: "",
    nwcTokenConfirm: false,
    tenant: ""
  });
  const [validTenant, setValidTenant] = useState(false);
  const validateTenant = (e) => {
    e.preventDefault();
    const tenant = e.target["validate-tenant"].value;
    setPartialTemplate(prevTemplateState => {
      return {...prevTemplateState, tenant: tenant}
    });
    if(!tenant) {
      return setValidatingTenantState(false);
    }
    setValidatingTenantState(true);
    setIsValidatingTenant(true);
    apiHandler(`https://us.nintex.io/organizations/v1/tenant/name-check?name=${tenant}`, "GET", null).then(tenantResponse => {
      if(tenantResponse.reason_code) {
        if(tenantResponse.reason_code === 1000) {
          setValidTenant(true); // Valid Tenant
          setIsValidatingTenant(false);
          setValidatingTenantState(true);
        } else {
          setValidTenant(false); // Invalid Tenant
          setIsValidatingTenant(false);
          setValidatingTenantState(false);
        }
      } else {
        if(tenantResponse.is_acceptable) {
          // Invalid Tenant
          setValidTenant(false);
          setIsValidatingTenant(false);
          setValidatingTenantState(false);
        }
      }
    }).catch(tenantError => {
      // console.lofg(tenantError);
      setValidTenant(false);
      setIsValidatingTenant(false);
      setValidatingTenantState(false);
    });
  }

  const closeModal = () => {
    setPartialTemplate(prevTemplateState => {
      return {
        nwcToken: "",
        nwcTokenConfirm: false,
        tenant: ""
      }
    });
    setValidTenant(false);
    setIsValidatingTenant(false);
    clickHandler(false);
  }

  const updateCounter = (templateId, dlCounter, tenant) => {
    let newCount = dlCounter + 1;
    apiHandler(`${uiSelector.url}/templates/v1/edit/${templateId}`, "PUT", {
      dl_counter: newCount,
      tenant: tenant
    }, null).then(counterUpdateResponse => {
      closeModal();
    }).catch(templateUpdateError => {
      // console.log(templateUpdateError);
      closeModal();
    })
  }

  const importTemplate = (e) => {
    e.preventDefault();
    updateCounter(templateDetail?.[0].templateId, templateDetail?.[0].dl_counter, partialTemplate.tenant);
    window.open(
      `https://${partialTemplate.tenant}.workflowcloud.com/dashboard/importworkflow?workflowKey=${templateDetail?.[0].nwc_token}&workflowName=${templateDetail?.[0].title}`,
      "_blank"
    );
  }
  
  return <>
    {(importTenantModal && !validTenant) && <form method="post" onSubmit={validateTenant}><div className={importTenantStyles["nintex-solution-share-template"]}>
        <div className={importTenantStyles["nintex-solution-modal-window"]}>
            <div>
                <Button onClick={closeModal} className={importTenantStyles["nintex-solution-modal-close"]}>X</Button>
                <h1>Supply Your Nintex Workflow Cloud Tenant Name</h1>
                <div>
                  <InputField type="text" id="validate-tenant" className={(!validatingTenantState && "invalid-input") + " br-5"} />
                </div>
                
                <div>
                  <p>
                    {(validatingTenantState) ? IMPORT_INSTRUCTIONS : IMPORT_INVALID}
                  </p>
                </div>

                <div align="right">
                  <Button type="submit" className={importTenantStyles["nintex-solution-validate-btn"]} disabled={isValidatingTenant}>Validate</Button>
                </div>
                {isValidatingTenant && <p>Validating...</p>}
            </div>
        </div>
    </div>
  </form>}
  {((importTenantModal && validTenant) && <form method="post" onSubmit={importTemplate}><div className={importTenantStyles["nintex-solution-share-template"]}>
    <div className={importTenantStyles["nintex-solution-modal-window"]}>
      <div className={importTenantStyles["nintex-solution-modal-window-long"]}>
        <Button type="button" onClick={closeModal} className={importTenantStyles["nintex-solution-modal-close"]}>X</Button>
        <h1>Nintex Workflow Cloud Direct Import</h1>
          <div>
            <p>Press 'import' to begin direct import of {<b>{templateDetail?.[0].title}</b>} into the tenant: {<b>{`https//${partialTemplate.tenant}.workflowcloud.com`}</b>}</p>
          </div>
          <div align="right">
            <Button type="submit" className={importTenantStyles["nintex-solution-validate-btn"]}>import</Button>
          </div>
        </div>
      </div>
    </div>
  </form>)}
  </>
}

export default ImportTenantModal;