// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Add Button */\n.LoadMore_nintex-solution-add-button__P9Uno {\n    display: flex;\n    justify-content: center;\n    margin: 30px 0px;\n}\n.LoadMore_nintex-solution-add-button__P9Uno svg {\n    width: 38px;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/LoadMore/LoadMore.module.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":["/* Add Button */\n.nintex-solution-add-button {\n    display: flex;\n    justify-content: center;\n    margin: 30px 0px;\n}\n.nintex-solution-add-button svg {\n    width: 38px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nintex-solution-add-button": "LoadMore_nintex-solution-add-button__P9Uno"
};
export default ___CSS_LOADER_EXPORT___;
