/**
 * @author Varun Kashyap
*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiHandler } from "../../../helpers/apiHelper";
import { responseHelper } from "../../../helpers/responseHelper";
import Loading from "../../UI/Loading/Loading";
import LoadMore from "../../UI/LoadMore/LoadMore";
import Card from "../../UI/Card/Card";
import PartnerCard from "../../UI/PartnerCard/PartnerCard";
import Filters from "../../UI/Filters/Filters";
import SectionalLoading from "../../UI/SectionalLoading/SectionalLoading";
import Carousel from "../../UI/Carousel/Carousel";
import { BROWSE_CAPABILITY_HEADING, BROWSE_PARTNER_HEADING, FILTERS, FILTER_TEMPLATES_TITLE } from "../../../constants/home";
import homeStyles from "./Home.module.css";
import {NO_CAPABILITY_RESULTS, NO_PARTNER_RESULTS, NO_TEMPLATE_RESULTS} from "../../../constants/index";
import ReactPaginate from 'react-paginate';

// Static Carousel For Phas 1.1 Release
const CAROUSEL = [
    {
        heading: "Nintex Solution Accelerator Gallery",
        subHeading: "ADAPT QUICKLY. MOVE FASTER",
        content: "Achieve more with your Nintex subscription by using pre-built process maps, automating templates, workflows, RPA botflows, connectors and more."
    },
    {
        heading: "Nintex Solution Accelerator",
        subHeading: "ADAPT QUICKLY. MOVE",
        content: "Achieve more with your Nintex subscription by using pre-built process maps, automating templates, workflows."
    },
    {
        heading: "Nintex Solution",
        subHeading: "ADAPT QUICKLY.",
        content: "Achieve more with your Nintex subscription by using pre-built process maps."
    }
]

/**
 * 
 * @returns Landing Home component for public gallery
*/
const Home = ({capabilities, partners, capabilityLoading}) => {
    const navigate = useNavigate(); // Naviagte hook to route the user throughout the application
    const uiSelector = useSelector(state => state.ui); // Global UI state.
    const [activeFilter, setActiveFilter] = useState("featured"); // Initial active filter.
    const [templates, setTemplates] = useState([]); // Initial Templates Data.
    const [carousels, setCarousels] = useState([]); // Initial Carousels Data.
    const [isLoading, setIsLoading] = useState(true); // Main page loading.
    const [isSectionLoading, setIsSectionLoading] = useState(false); // Sectional loading for cool templates.
    const [canLoadMore, setCanLoadMore] = useState(false); // To check if the current data has the privilages to load more content or not.
    const [currentPage, setCurrentPage] = useState(1); // Initial page to load content for first page.
    const [totalCount, setTotalCount] = useState(0); // Initial page to load content for first page.

    // Change Filter For Cool Templates
    const changeFilterHandler = (e) => {
        const newSelectedFilter = e.target.id;
        if(newSelectedFilter) {
            setActiveFilter(newSelectedFilter);
            setTotalCount(0)
            getCoolTemplates(12, 1, newSelectedFilter);
        }
    };
    
    useEffect(() => {
        setIsSectionLoading(true);
        getActiveCarousel();
        let homePageLimit = 12;
        getCoolTemplates(homePageLimit, currentPage, activeFilter);
    }, [currentPage, uiSelector.url, uiSelector.limit]);

    const getCoolTemplates = (homePageLimit = 12, currentPage = 1, activeFilter = 'featured') => {
        setIsSectionLoading(true);
        apiHandler(`${uiSelector.url}/templates/v1/templategallery`, "POST", {type: activeFilter, limit: (homePageLimit), currentPage: currentPage}, null).then(coolTemplatesResponse => {
            if(coolTemplatesResponse.statusCode === responseHelper[200].statusCode) {
                setIsLoading(false);
                setIsSectionLoading(false);
                setTotalCount(Number(coolTemplatesResponse.total_count)/12);
                if(Number(coolTemplatesResponse.total_count) > homePageLimit) {
                    setCanLoadMore(true);
                } else {
                    setCanLoadMore(false);
                }
                if(Number(coolTemplatesResponse.data.length) >= Number(coolTemplatesResponse.total_count)) {
                    setCanLoadMore(false);
                }
                setTemplates(coolTemplatesResponse.data);
            } else {
                setIsLoading(false);
                setIsSectionLoading(false);
            }
        }).catch(coolTemplatesError => {
            // Error
            setIsLoading(false);
            setIsSectionLoading(false);
        });
    }

    const templateDetailHandler = (e) => {
        let ele = e.target;
        while (ele) {
            ele = ele.parentNode;
            if(ele.id) break;
        }
        
        if(ele.id && ele.id !== "root" && ele.id !== "") {
            navigate(`/t/${ele.id}`);
        }
    }

    const changePageHandler = (event) => {
        setCurrentPage(event.selected + 1);
    }
    const getActiveCarousel = () => {
        apiHandler(`${uiSelector.url}/carousel/v1/findActiveCarousel`, "GET", null, null).then(response => {
            if (response.statusCode === responseHelper[200].statusCode) {
                setCarousels(response.data);
            }
        })
    }
    return <>
        {!isLoading && <div className={homeStyles["nintex-solution-home"]}>
            {/* Banner Starts */}
            {carousels.length > 0 &&
                <Carousel carousel={carousels.length > 0 ? carousels: CAROUSEL} />
            }
            {/* Browse by capability */}
            <section className={homeStyles["nintex-solution-second-section"]}>
                <div className={homeStyles.container}>
                    <h2>{BROWSE_CAPABILITY_HEADING}</h2>
                    <div className={homeStyles["nintex-solution-cards"]}>
                        {capabilities.map((cc, key) => (
                            (cc.is_visible === 1) && <Card key={key} id={cc._id} title={cc.name} description={cc.description} image={cc.icon_image} showDescription={true} type={"capability"}/>
                        ))}
                        {!capabilityLoading && capabilities.length === 0 && <p><i>{NO_CAPABILITY_RESULTS}</i></p>}
                    </div>
                </div>
            </section>
            {/* Browse by partners */}
            <section className={homeStyles["nintex-solution-second-section"] + " pt-80"}>
                <div className={homeStyles.container}>
                    <h2>{BROWSE_PARTNER_HEADING}</h2>
                    <div className={homeStyles["nintex-solution-cards"]}>
                        {partners?.map((cc, key) => (
                            (cc.is_visible === 1) && <PartnerCard key={key} id={cc.name.replace(" ", "-")} title={cc.name} description={cc.description} image={cc.small_icon} showDescription={true} type={"partner"}/>
                        ))}
                        {partners.length === 0 && <p><i>{NO_PARTNER_RESULTS}</i></p>}
                    </div>
                </div>
            </section>
            {/* Cool and new templates */}
            <section className={homeStyles["nintex-solution-second-section"] + " pt-80"}>
                <div className={homeStyles.container}>
                    <h2>{FILTER_TEMPLATES_TITLE}</h2>
                    {!isSectionLoading && <><div className={homeStyles["nintex-solution-filters"]}>
                        <Filters activeFilter={activeFilter} filters={FILTERS} onChangeHandler={changeFilterHandler} />
                    </div>
                    <div className={homeStyles["nintex-solution-cards"]} onClick={templateDetailHandler}>
                        {templates.map((ft, key) => (
                            <Card id={ft._id} friendlyURL={ft.friendly_url} key={key} className="pointer" featured={ft.is_featured} title={ft.title} image={ft.capability_image} language={ft.language} status={ft.status} createdDate={ft.created_on} createdBy={ft.partner} likes={ft.likes} dlCounter={ft.dl_counter} capability={ft.capability} showDescription={false} type={"templates"}/>
                        ))}
                        {!isLoading && templates.length === 0 && <p><i>{NO_TEMPLATE_RESULTS}</i></p>}
                    </div></>}
                    {isSectionLoading && <div className="text-center"><SectionalLoading /></div>}
                </div>
            </section>
            {/* {totalCount > 1 && 
            <div className={homeStyles["nintex-solution-pagination"]}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    activeLinkClassName={homeStyles["nintex-solution-pagination-active"]}
                    disabledClassName={homeStyles["nintex-solution-pagination-disable"]}
                    onPageChange={changePageHandler}
                    initialPage={currentPage - 1}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={totalCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </div>
            } */}
            {/* {(canLoadMore && !isSectionLoading) && <LoadMore clickHandler={changePageHandler} />} */}
        </div>}
        {isLoading && <div className="text-center"><Loading /></div>}
    </>
};

export default Home;