/**
 * @author Varun Kashyap
*/
import React from "react";
import styled from "styled-components";
/**
 * Dynamic theme branding
*/
const IMG = styled.img`
    background-color: ${props => (props.subComponent ? props.theme[props.component].image[props.subComponent]?.bg : props.theme[props.component]?.bg) || "#296CCF"}
`;
/**
 * 
 * @param {*} props 
 * @returns ListItem component for public gallery
 */
const Image = ({children, component, subComponent, theme, className, src, onClick, id}) => {
    return <IMG id={id} onClick={onClick} component={component} subComponent={subComponent} theme={theme} className={className || null} src={src}>{children}</IMG>
};

export default Image;