import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiHandler } from "../../../helpers/apiHelper";
import { responseHelper } from "../../../helpers/responseHelper";
import Loading from "../../UI/Loading/Loading";
import TemplateSetStyles from "./TemplateSet.module.css";

const TemplateSet = () => {
    const params = useParams();
    const {templateSetId} = params;
    const uiSelector = useSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState(true);
    const [templateSetDetail, setTemplateSetDetail] = useState([]);
    useEffect(() => {
        apiHandler(`${uiSelector.url}/templateset/v1/findByUrl/${templateSetId}`, "GET", null, null).then(templateSetResponse => {
            if(templateSetResponse.statusCode === responseHelper[200].statusCode) {
                setTemplateSetDetail(templateSetResponse.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(templateSetError => {
            setIsLoading(false);
        });
    }, [templateSetId, uiSelector.url]);
    return(
        <>
            {(!isLoading && templateSetDetail.length > 0) && <><section className={TemplateSetStyles["nintex-solution-template-set-page"]}>
                <div className={TemplateSetStyles["container"]}>
                    <h1>Template Set Title</h1>
                </div>
            </section>
            <section className={TemplateSetStyles["nintex-solution-template-set"]}>
                <div className={TemplateSetStyles["container"]}>
                    <h4>
                        Template Set Content: 
                    </h4>
                    <h4>Optimum is a modern, full-services Software Development and Consulting company, transforming businesses through the power of People, Innovative Technologies, and Automated Processes. By utilizing our expertise and years of experience in strategic planning and innovative software technologies, our team of certified experts help clients gain efficiency and productivity, reduce operational and labor costs, ensure compliance, and enable digital transformation.</h4>
                    <h4> We offer a full suite of software services and solutions, including Custom Software Development and Systems Integration, Office 365 and Cloud Solutions, Business Process Management and Automation, Business Intelligence (BI), Enterprise Content and Document Management, Project Management Software and Consulting Services, Blockchain Solutions, and IT Staff Augmentation.</h4>
                    <h4> As a Gold Microsoft Partner and certified consulting partner of various industry-leading software solutions, we have a team of certified Architects, Developers, Integrators, UX/UI Designer, Process Engineers, Analysts, and Project Managers who believe in quality, speed, integrity, and digital enablement. Our passion is to help our clients gain more clarity, peace of mind, and efficiency and align their Digital Solutions to impactful Business Outcomes.</h4>
                    </div>
            </section></>}
            {(!isLoading && templateSetDetail.length === 0) && <><section className={TemplateSetStyles["nintex-solution-template-set-page"]}>
                <div className={TemplateSetStyles["container"]}>
                    <h1><i>No template set found.</i></h1>
                </div>
            </section>
            <div className="empty_content"></div></>}
            {isLoading && <div className="text-center"><Loading /></div>}
        </>
    );
};

export default TemplateSet;
