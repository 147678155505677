import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotFoundImg from "../../../assets/images/NotFound.svg";
import { NF_BANNER_TITLE, NF_BTN_CONTENT, NF_MAIN_DESC } from "../../../constants/notfound";
import NotFoundStyles from "./NotFound.module.css";

const NotFound = () => {
    const [buttonContent, setButtonContent] = useState(NF_BTN_CONTENT);
    const navigate = useNavigate();
    useEffect(() => {
        const referrer = document.referrer;
        // console.log(referrer);
        if(referrer === "") {
            // Direct approach.
            setButtonContent("Take me back");
        }
    }, []);
    return(
        <>
            <section className={NotFoundStyles["nintex-solution-top-image"]}>
                <h1>{NF_BANNER_TITLE}</h1>
                <h4>{NF_MAIN_DESC}</h4>
                <button onClick={() => navigate("/")}>{buttonContent}</button>
            </section>
            <div className="nintex-solution-notfound-section">
                <img src={NotFoundImg} alt="not-found" className="w-100" />
            </div>
        </>
    );
};

export default NotFound;