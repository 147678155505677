export const GALLERY_TITLE = "Gallery";
export const WELCOME_TEXT = "Hello";
export const LOGOUT_TEXT = "Logout";
export const HAMBURGER_ITEMS = [{
    title_id :"nintex",
    data_id :"https://nintex.com",
    Title  : "Nintex.com"
},
{
    title_id : "community",
    data_id : "https://community.nintex.com",
    Title   : "Nintex Community"
},
{
    title_id : "trial",
    data_id : "https://nintex.com/trial",
    Title  : "Start Free Trial"
}
]