export const TOTAL_SEARCH_COUNT = "results for";
export const SORT_BY_TEXT = "Sort By";
export const SORT_FILTERS = [
    {
        id: "featured",
        name: "Featured"
    },
    {
        id: "dlDESC",
        name: "Most Downloaded"
    },
    {
        id: "dtDESC",
        name: "Newest"
    },
    {
        id: "tASC",
        name: "A-Z"
    }
    
];
export const CAPABILITY = ["Process Maps", "RPA Botflows", "Nintex K2 Cloud", "Workflows", "Connectors", "Tools"]
export const FILTER_TEXT = "FILTERS";
export const MULTIPLE_CAPABILITIES = "multiple capabilities"