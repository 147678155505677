/**
 * @author Varun Kashyap
*/
import React from "react";
import { FILTER_TITLE } from "../../../constants/filters";
import filterStyles from "./Filters.module.css";
/**
 * 
 * @returns Filter component for public gallery
*/
const Filters = ({activeFilter, filters, onChangeHandler}) => {
    return <div className={filterStyles["nintex-solution-filters"]}>
        <ul className={filterStyles["nintex-solution-filters-list"]} onClick={onChangeHandler}>
            <li>{FILTER_TITLE} </li>
            {filters.map((flt, key) => (
                <React.Fragment key={key}>
                    <li>
                        <span id={flt.id} className={(flt.id === activeFilter) ? filterStyles.active : null}>{flt.label}</span>
                    </li>
                </React.Fragment>
            ))}
        </ul>
    </div>
};

export default Filters;