/**
 * @author Varun kashyap
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import groupStyles from "./Group.module.css";
/**
 * @returns Group component for public gallery.
 */
const Group = ({className, contentClassName, partnerId, image, title, content, subContent}) => {
    const navigate = useNavigate();
    const redirectToPartnersPage = () => {
        navigate(`/p/${content?.replace(/ /g, "-")?.toLowerCase()}`);
    };

    return <>
        <div className={groupStyles["nintex-solution-template-detail-icon-left-section"] + " " + className || null}>
            <img src={require("../../../assets/images/"+image)} alt="publisher-icon" />
        </div>
        <div className={groupStyles["nintex-solution-template-detail-icon-right-section"]}>
            <div className={groupStyles["nintex-solution-template-detail-icon-title"]}>
                <h5>{title}</h5>
            </div>
            <div className={groupStyles["nintex-gallery-icon-description"]}>
                {partnerId && <p className={groupStyles[className] + " " + contentClassName} onClick={redirectToPartnersPage}>{content}</p>}
                {!partnerId && <>
                    <p className={groupStyles[className]}>{content}</p>
                    <p>{subContent}</p>
                </>}
            </div>
        </div>
    </>
};

export default Group;