export const FOOTER_ITEMS = [
	{
		name: "Privacy Policy",
		link: "https://www.nintex.com/legal/privacy-policy/"
	},
	{
		name: "Legal",
		link: "https://www.nintex.com/legal/"
	},
	{
		name: "Security",
		link: "https://www.nintex.com/security/"
	},
	{
		name: "Terms of Use",
		link: "https://www.nintex.com/legal/website-and-community-terms-of-use/"
	},
	];
export const FOOTER_COPYRIGHT = "© 2024 Nintex";