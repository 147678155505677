/**
 * @author Varun Kashyap
 */
import React from "react";
import { DISCLAIMER_CONTENT_DESC, DISCLAIMER_CONTENT_LINK, DISCLAIMER_CONTENT_TITLE } from "../../../constants/disclaimer";
import disclaimerStyles from "./Disclaimer.module.css";
/**
 * 
 * @returns 
 */
const Disclaimer = () => {
    return  <section className={disclaimerStyles["nintex-gallery-4-section-4"]}>
        <div className={disclaimerStyles.container}>
            <div className={disclaimerStyles["nintex-solution-disclamair-section"]}>
                <div className={disclaimerStyles["nintex-solution-disclamair-section-icon"]}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#555">
                        <path fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd" />
                    </svg>
                    <h4>{DISCLAIMER_CONTENT_TITLE}</h4>
                </div>
                <div className={disclaimerStyles["nintex-solution-disclamair-section-description"]}>
                    <p>{DISCLAIMER_CONTENT_DESC}<a
                            href="https://www.nintex.com/legal">{DISCLAIMER_CONTENT_LINK }</a></p>
                </div>
            </div>
        </div>
    </section>
};

export default Disclaimer;