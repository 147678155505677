/**
 * @author Varun Kashyap
*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ListItem from "../ListItem/ListItem";
import Div from "../Div/Div";
import Image from "../../UI/Image/Image";
import hamBurger from "../../../assets/images/Hamburger.png"
import searchImage from "../../../assets/images/Search Icon.png"
import mobileImage from "../../../assets/images/nintex-mob-logo.png"
import { GALLERY_TITLE, HAMBURGER_ITEMS } from "../../../constants/header.js";
import headerStyles from "./Header.module.css";
import layoutStyles from "../../Core/Layout/Layout.module.css";
import InputField from "../InputField/InputField";
import { getQueryParams } from "../../../helpers";
import { apiHandler } from "../../../helpers/apiHelper";
/**
 * 
 * @returns Header component for public gallery
*/
const Header = ({capabilities, searchHandler}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let query = location.search;
    query = getQueryParams(query);
    const [menu, setMenu] = useState(false);
    useEffect(() => {
        if(location.pathname.toLowerCase() !== "/search") {
            sessionStorage.removeItem("search");
            document.getElementById("search_form").reset();
            setSearch({});
        }
        if (menu) {
            document.addEventListener('click', openMenuHandler,true);
            return ()=> {
                document.removeEventListener('click', openMenuHandler, false);
            }
        }
    }, [location.pathname, menu]);
    const uiSelector = useSelector(state => state.ui);
    const { theme } = uiSelector;
    const [search, setSearch] = useState(query || {});
    const [formState, setFormState] = useState("");
    const searchIconClick = (e, form_name)=>{
        e.preventDefault();
        let a = document.getElementById(form_name);
        e.target = a;
        searchTemplates(e);
    }
    const searchTemplates = (e) => {
        e.preventDefault();
        const searchObj = {};
        for (const formEle of e.target) {
            if (formEle.id === "button") {
                continue;
            }
            console.log(formEle.value);
            if(formEle.id === "capability"){
                if (formEle.value.toLowerCase() === "all") {
                    searchObj[formEle.id] = "all";
                }else{
                let capabilityId = capabilities.find(dt => dt.name.toLowerCase() === formEle.value)
                // console.log(capabilityId?._id);
                searchObj[formEle.id] = capabilityId._id;
                }
            }else{
                searchObj[formEle.id] = formEle.value;
            }
        }
        if(Object.values(searchObj).includes("")) {
            setFormState("invalid");
        } else {
            setSearch(searchObj);
            searchHandler(searchObj);
            if(searchObj.search_term) {
                apiHandler(`${uiSelector.url}/stats/v1/create`, "POST", {
                    title: searchObj.search_term,
                    capability: searchObj.capability
                }, null).then(statsResponse => {
                    // console.log(statsResponse)
                }).catch(statsError => {
                    // console.log(statsError);
                });
            }
            navigate(`search?search_term=${searchObj.search_term}&capability=${searchObj.capability}&department=&industry=&partner=&capabilities=&aggregation=${JSON.stringify([])}&index=${JSON.stringify([])}`);
            setFormState("");
        }
    };

    const redirectToHome = () => {
        document.getElementById("search_form").reset();
        setSearch({});
        navigate("");
    };

    const openMenuHandler = () => {
        setMenu(!menu);
    };

    const dynamicWidthHandler = (e) => {
        const targetOptions = e.target.options.selectedIndex;
        if(e.target[targetOptions].text) {
            let width = (e.target[targetOptions].text.length * 10) + 20 + "px";
            if(e.target[targetOptions].text.length >= 12) {
                width = "115px";
            } else if(e.target[targetOptions].text.length >= 9) {
                width = (e.target[targetOptions].text.length * 10) + 10 + "px";
            } else if(e.target[targetOptions].text.length >= 5) {
                width = (e.target[targetOptions].text.length * 15) - 10 + "px";
            } else if(e.target[targetOptions].text.length == 3) {
                width = (e.target[targetOptions].text.length * 15) + 15 + "px";
            }
            e.target.style.width = width;
        }
    }
    return <div className={headerStyles["nintex-header"]}>
        <Div component="header" theme={theme} className={headerStyles["nintex-solution-nav"]}>
            <div className={layoutStyles.container}>
                <div className={headerStyles["nintex-solution-nav-main"]}>
                    <div className={headerStyles["nintex-solution-nav-list"]}>
                        <ListItem component="header" theme={theme} onClick={redirectToHome}>
                            <img src="/assets/images/Nintex Nav Logo.png" alt="Nintex-Main-Logo" />
                            <span className={headerStyles["nintex-logo-text"]}>{GALLERY_TITLE}</span>
                        </ListItem>
                        
                    </div>
                </div>
                <div className="w-70 d-inline-flex justify-content-center">
                    <form id="search_form" method="POST" onSubmit={searchTemplates} className={`d-flex`} style={{width:"90%"}}>
                        <select id="capability" className={headerStyles["nintex-nav-options"]} defaultValue={search.capability} onChange={dynamicWidthHandler}>
                            <option key="all" value="all">All</option>
                            {capabilities.map((cp, key) => (
                                (cp.is_visible === 1) && <option key={key} data-name={cp._id} value={cp.name.toLowerCase()}>{cp.name}</option>
                            ))}
                        </select>
                        {(Object.keys(search).length > 0 || Object.keys(query).length > 0) && <InputField type="search" id="search_term" className={(formState === "invalid" && "invalid-input") + " " + headerStyles["nintex-nav-search"]} defaultValue={decodeURI(search.search_term || query.search_term)} />}
                        {(Object.keys(search).length === 0 && Object.keys(query).length === 0) && <InputField type="search" id="search_term" className={(formState === "invalid" && "invalid-input") + " " + headerStyles["nintex-nav-search"]} defaultValue="" />}
                    </form>
                    <Image id={"search-icon"} onClick={(e)=>searchIconClick(e, "search_form")} component="header" subComponent="searchIcon" theme={theme} src={searchImage}className={headerStyles["nintex-nav-search-icon"]} alt="search-icon" />
                </div>
                {/* <div className={headerStyles["nintex-solution-nav-main"]}>
                    <ul className={headerStyles["nintex-solution-nav-list"]}>
                        <ListItem component="header" theme={theme} onClick={redirectToHome}>
                            <img src="/assets/images/Nintex Nav Logo.png" alt="Nintex-Main-Logo" />
                            <span className={headerStyles["nintex-logo-text"]}>{GALLERY_TITLE}</span>
                        </ListItem>
                        <li>
                            <form id="search_form" method="POST" onSubmit={searchTemplates} className={formState === "invalid" ? headerStyles["invalid"] : null}>
                                <select id="capability" className={headerStyles["nintex-nav-options"]} defaultValue={search.capability} onChange={dynamicWidthHandler}>
                                    <option key="All" value="All">All</option>
                                    {capabilities.map((cp, key) => (
                                        <option key={key} value={cp.name}>{cp.name}</option>
                                    ))}
                                </select>
                                <input type="search" id="search_term" className={headerStyles["nintex-nav-search"]} placeholder="search term" defaultValue={search.search_term} />
                            </form>
                            <Image component="header" subComponent="searchIcon" theme={theme} src={searchImage}className={headerStyles["nintex-nav-search-icon"]} alt="search-icon" />
                        </li>
                    </ul>
                </div> */}
                <div className={headerStyles["nintex-solution-right-nav-icons"]+ " d-flex align-items-center justify-content-center"}>
                    <ul className={headerStyles["nintex-solution-nav-icons"]}>
                        {/* <ListItem component="header" theme={theme}>{WELCOME_TEXT} Varun!</ListItem>
                        <ListItem component="header" theme={theme}>{LOGOUT_TEXT}</ListItem>
                        <ListItem component="header" theme={theme}><img src={userImage} /></ListItem> */}
                        <ListItem component="header" theme={theme} onClick={openMenuHandler}>
                            <img src={hamBurger} alt="hamburger" />
                            {menu && <div className={headerStyles["nintex-solution-hamburger-wrapper"]}><div className={headerStyles["nintex-solution-hamburger-dropdown"]}>
                                <ul className={headerStyles["nintex-solution-hamburger-dropdown-menu"]} onClick={(e) => e.target.id && (window.location.href = e.target.dataset.id)}>
                                    {HAMBURGER_ITEMS.map(ht => (
                                        <li key={ht.data_id} data-id={ht.data_id} id={ht.title_id}><a href={ht.data_id} target="_blank">{ht.Title}</a></li>
                                    ))}
                                </ul>
                            </div></div>}
                        </ListItem>
                    </ul>
                </div>
            </div>
        </Div>
        <div>
            <nav className={headerStyles["nintex-solution-mobile-nav"]}>
                <div className={headerStyles.container}>
                    <div className={headerStyles["nintex-solution-nav-mobile-main"]}>
                        <ul className={headerStyles["nintex-solution-mobile-list"]}>
                            <li onClick={redirectToHome}><img src={mobileImage} alt="Nintex-Main-Logo" /></li>
                        </ul>
                        <div className={headerStyles["nintex-solution-mobile-icons"]}>
                            {/* <img src={userImage} className={headerStyles["nintex-user-img"]} /> */}
                            <img src={hamBurger} alt="hamburger" onClick={openMenuHandler} />
                            {menu && <div className={headerStyles["nintex-solution-hamburger-wrapper"]}><div className={headerStyles["nintex-solution-hamburger-dropdown"]}>
                                <ul className={headerStyles["nintex-solution-hamburger-dropdown-menu"]} onClick={(e) => e.target.id && (window.location.href = e.target.dataset.id)}>
                                    {HAMBURGER_ITEMS.map(ht => (
                                        <li key={ht.data_id} data-id={ht.data_id} id={ht.title_id}><a href={ht.data_id} target="_blank">{ht.Title}</a></li>
                                    ))}
                                </ul>
                            </div></div>}
                        </div>
                    </div>
                    <div className={headerStyles["nintex-solution-mobile-search"]}>
                        <form id="search_form_2" method="POST" onSubmit={searchTemplates} className={`${formState === "invalid" ? "invalid-input" : null} d-flex`} style={{width:"90%"}}>
                            <select id="capability" className={headerStyles["nintex-nav-options"]} defaultValue={search.capability || query.capability} onChange={dynamicWidthHandler}>
                                <option key="All" value="All">All</option>
                                {capabilities.map((cp, key) => (
                                    <option key={key} data-name={cp._id} value={cp.name.toLowerCase()}>{cp.name}</option>
                                ))}
                            </select>
                            {Object.keys(search).length > 0 && <InputField type="search" id="search_term" className={(formState === "invalid" && "invalid-input") + " " + headerStyles["nintex-nav-search"]} defaultValue={search.search_term} />}
                            {Object.keys(search).length === 0 && <InputField type="search" id="search_term" className={(formState === "invalid" && "invalid-input") + " " + headerStyles["nintex-nav-search"]} defaultValue="" />}
                        </form>
                        <img src={searchImage} id={"search-icon"} onClick={(e)=>searchIconClick(e, "search_form_2")} className={headerStyles["nintex-nav-search-icon"]} placeholder="wwww" alt="search-icon" />
                    </div>
                </div>
            </nav>
        </div>
    </div>
};

export default Header;