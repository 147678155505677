import React from "react";
import loadingStyles  from "./Loading.module.css";
const Loading = (props) => {
    // return <div className={loadingStyles["loading"]}>
    //     <img src={loading} height={props.height} width={props.width} />
    // </div>
    return <div className={loadingStyles["loading"]}>
        <div className={loadingStyles.container}>
            <div className={loadingStyles.item + " " + loadingStyles["item-1"]}></div>
            <div className={loadingStyles.item + " " + loadingStyles["item-3"]}></div>
        </div>
    </div>
};

export default Loading;