/**
 * @author Varun Kashyap
 */
import Axios from 'axios';
/**
 * 
 * @param  {...any} args 
 * @returns http requests handler
 */
export const apiHandler = (...args) => {
    const [URL, METHOD, DATA, HEADERS, RESPONSETYPE] = args;
    const requestHandlerObject = {
        method: METHOD,
        url: URL,
        header: !HEADERS ? {
            'Content-Type': 'application/json',
        } : HEADERS,
        responseType: !RESPONSETYPE ? "text" : RESPONSETYPE,
        data: DATA
    };

    return new Promise(async (resolve, reject) => {
        Axios(requestHandlerObject).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}