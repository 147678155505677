/**
 * @author Varun Kashyap
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import carouselStyles from "./Carousel.module.css";
import layoutStyles from "../../Core/Layout/Layout.module.css";
import { currentURL } from "../../../helpers";

/**
 * 
 * @returns Carousel component for gallery app.
 */
 const SECTION = styled.section`
 background-image: url(${props => props.bgImage || null});
`;
const Carousel = ({carousel}) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const carouselData = [...carousel];
    const changeSlide = (e) => {
        const carousel = e.target.id;
        if(carousel) {
            setActiveSlide(carousel);
        }
    }
    useEffect(() => {
        let currentSlide = activeSlide;
        setInterval(() => {
            if (currentSlide == (carouselData.length - 1)) {
                currentSlide = 0;
            } else {
                currentSlide += 1;
            }
            setActiveSlide(currentSlide);
        }, 20000);
    }, [])
    return <>
        <section className={carouselStyles["nintex-solution-banner"]}>
            <div className={layoutStyles.container}>
                <div className={carouselStyles["nintex-solution-banner-section"]}>
                    <div className={carouselStyles["nintex-banner-left-section"]}>
                        {carouselData[activeSlide]?.title && <h1>{carouselData[activeSlide].title}</h1>}
                        {carouselData[activeSlide]?.sub_title && <h5>{carouselData[activeSlide].sub_title}</h5>}
                        {carouselData[activeSlide]?.description && <div className={carouselStyles["nintex-banner-left-description"]} dangerouslySetInnerHTML={{__html: carouselData[activeSlide].description}}></div>}
                        <div className={carouselStyles["nintex-solution-carousel-dots"]}>
                            <ol className={carouselStyles["carousel-indicators"]} onClick={changeSlide}>
                                {carousel.length > 0 && carousel.map((cc, key) => (
                                    <li key={key} id={key} data-target={`carousel_${key}`} data-slide-to={key} className={(Number(activeSlide) === Number(key)) ? carouselStyles.active : ""}></li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    <div className={carouselStyles["nintex-banner-right-section"]}>
                        <img src={carouselData[activeSlide]?.slide_img} alt="Nintex-banner" />
                        <ol className={carouselStyles["carousel-indicators-mobile"]} onClick={changeSlide}>
                            {carousel.length > 0 && carousel.map((cc, key) => (
                                    <li key={key} id={key} data-target={`carousel_${key}`} data-slide-to={key} className={(Number(activeSlide) === Number(key)) ? carouselStyles.active : ""}></li>
                            ))}
                            {/* <li data-target="#myCarousel" data-slide-to="0" className={carouselStyles.active}></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li> */}
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    </>
};

export default Carousel;