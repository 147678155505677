/**
 * @author Varun Kashyap
 */
import React from "react";
import Button from "../Button/Button";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton
} from "react-share";
import shareTemplateStyles from "./ShareTemplate.module.css";
import { currentURL } from "../../../helpers";
/**
 * 
 * @returns Share template model component for public gallery.
 */
const ShareTemplate = ({canShareTemplates, clickHandler, templateDetail}) => {
    const URL = `${currentURL()}/t/${templateDetail?.[0].friendly_url}`;
    return canShareTemplates && <div className={shareTemplateStyles["nintex-solution-share-template"]}>
        <div className={shareTemplateStyles["nintex-solution-modal-window"]}>
            <div className={shareTemplateStyles["nintex-solution-share-template"]}>
                <Button onClick={() => clickHandler(false)} className={shareTemplateStyles["nintex-solution-modal-close"]}>X</Button>
                <h1>Share Template</h1>
                <div>
                    <ul className={shareTemplateStyles["nintex-solution-share-modal"]}>
                    <li>
                        <FacebookShareButton hashtag="#nintex #processgallery #modernworkplace" url={URL} title={templateDetail?.[0].title} >
                            <FacebookIcon logofillcolor="white" size="48" round />
                            Facebook
                        </FacebookShareButton>
                    </li>
                    <li>
                        <LinkedinShareButton url={URL} title={templateDetail?.[0].title} >
                            <LinkedinIcon logofillcolor="white" size="48" round />
                            LinkedIn
                        </LinkedinShareButton>
                    </li>
                    <li>
                        <TwitterShareButton hashtags={["nintex", "processgallery", "modernworkplace"]} url={URL} title={templateDetail?.[0].title} >
                            <TwitterIcon logofillcolor="white" size="48" round />
                            Twitter
                        </TwitterShareButton>
                    </li>
                    <li>
                        <RedditShareButton url={URL} title={templateDetail?.[0].title}>
                            <RedditIcon logofillcolor="white" size="48" round />
                            Reddit
                        </RedditShareButton>
                    </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
};

export default ShareTemplate;