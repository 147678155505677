import { createSlice } from "@reduxjs/toolkit";
import { THEME } from "../constants/theme";
const { REACT_APP_BASE_URL, REACT_APP_TEMPLATE_LIMIT } = process.env

const initialState = {
    theme: THEME,
    // Until we consolidate variables like this to the node_env config file
    // I am pulling this out to an environment variabble
    url: REACT_APP_BASE_URL + "/api",
    limit: Number(REACT_APP_TEMPLATE_LIMIT) || 40
}

const uiSlice = createSlice({
    name: "UISlice",
    initialState,
    reducers: {}
});

export const uiActions = uiSlice.actions;
export default uiSlice;