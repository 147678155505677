/**
 * @author Varun Kashyap
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiHandler } from "../../../helpers/apiHelper";
import { responseHelper } from "../../../helpers/responseHelper";
import { capitalize, generateQuery, getQueryParams } from "../../../helpers";
import Card from "../../UI/Card/Card";
import LoadMore from "../../UI/LoadMore/LoadMore";
import Button from "../../UI/Button/Button";
import Loading from "../../UI/Loading/Loading";
import { FILTER_TEXT, SORT_BY_TEXT, SORT_FILTERS, TOTAL_SEARCH_COUNT} from "../../../constants/search";
import { NO_TEMPLATE_RESULTS, NO_TEXT } from "../../../constants";
import searchStyles from "./Search.module.css";
import ReactPaginate from "react-paginate";
let interval;
let selectedResourceFilters = {};
let selectedFilters = [];
let selectedFiltersName = [];
let total_count = 0;
/**
 * 
 * @returns Search component for public gallery
*/
const Search = ({searchTerm}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = getQueryParams(location.search);
    const uiSelector = useSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState(true);
    const [activeResource, setActiveResource] = useState(null);
    const [resources, setResources] = useState({});
    const [searchedTemplates, setSearchedTemplates] = useState([]);
    const [search, setSearch] = useState({});
    const [activeFilters, setActiveFilters] = useState([]);
    const [activeFiltersName, setActiveFiltersName] = useState([]);
    const [selectedFiltersData, setSelectedFiltersData] = useState({});
    const [sort, setSort] = useState(query.sortBy || "");
    const [totalCount, setTotalCount] = useState(0);

    const [canLoadMore, setCanLoadMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(Number(query.currentPage) || 1);


    /*const [totalSearchedTemplates, setTotalSearchTemplates] = useState(0);

    var totalCount = (total_count === 0) ? NO_TEXT : total_count.toString();
    
    var searchForText
    var hasCapFilter = false;
    if (searchTerm.search_term)
        searchForText = decodeURI(searchTerm.search_term)
    else {
        searchForText = decodeURI(searchTerm.capability)
        if(resources.capabilities){
            for (const cap of resources.capabilities){
                if(activeFilters.includes(cap.name)){
                    if (hasCapFilter){
                        searchForText = MULTIPLE_CAPABILITIES
                        break;
                    }else{
                        searchForText = cap.name;
                        hasCapFilter = true;
                    }//endif
                }//endif
            }//endfor   
        }//endif
    }//endelse*/

    useEffect(() => {
        setIsLoading(true);
        selectedResourceFilters = {};
        selectedFilters = [];
        selectedFiltersName = [];
        total_count = 0;
        // Checking search filters
        const filters = {};    
        if(query.capability?.toLowerCase() !== "all") {
            // Setting active filters if any
            let activeFilters = [
                ...query.capability?.split(","), 
                ...query.industry?.split(","), 
                ...query.department?.split(","), 
                ...query.partner?.split(",")
            ];
            activeFilters = activeFilters.filter(af => af !== "");
            setActiveFilters(activeFilters);
        }

        setSelectedFiltersData(JSON.parse(decodeURIComponent(query.aggregation)));
        setActiveFiltersName(JSON.parse(decodeURIComponent(query.index)));

        filters.capability = query.capability?.split(",");
        filters.industry = query.industry?.split(",");
        filters.department = query.department?.split(",");
        filters.partner = query.partner?.split(",");
        filters.searchTerm = query.search_term;
        filters.capabilities = decodeURIComponent(query.capabilities);
        filters.limit =  Number(uiSelector.limit);
        filters.sort = sort;
        filters.page = (localStorage.getItem('search') && localStorage.getItem('search') === searchTerm.search_term) ? currentPage: (Number(query.currentPage) || 1);
        console.log(query);
        apiHandler(`${uiSelector.url}/search/v1/filter`, "POST", filters, null).then(searchedTemplatesResponse => {
            localStorage.setItem("search", searchTerm.search_term)
            if(searchedTemplatesResponse.statusCode === responseHelper[200].statusCode) {
                total_count = Number(searchedTemplatesResponse.total_count);
                setTotalCount(total_count);
                if(Number(searchedTemplatesResponse.total_count) > Number(uiSelector.limit)) {
                    setCanLoadMore(true);
                } else {
                    setCanLoadMore(false);
                }
                if(Number(searchedTemplatesResponse.data.length) >= Number(searchedTemplatesResponse.total_count)) {
                    setCanLoadMore(false);
                }
                setSearchedTemplates(searchedTemplatesResponse.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(searchedTemplatesError => {
            setSearchedTemplates([]);
            setIsLoading(false);
        });
    }, [location.search, currentPage, searchTerm, search])
    /**
     * @description Get all resources.
    */
    useEffect(() => {
        apiHandler(`${uiSelector.url}/resource/v1/find`, "GET", null, null).then(resourcesResponse => {
            if(resourcesResponse.statusCode === responseHelper[200].statusCode) {
                const reso = {...resourcesResponse.data};
                if(!searchTerm.search_term && searchTerm.capability) {
                    if(Object.keys(reso).length > 0) {
                        setActiveResource("industry");
                        delete reso["capabilities"];
                    }
                    setResources(reso);
                } else {
                    setActiveResource(null);
                    setResources(reso);
                }
            }
        }).catch(resourcesError => {
            setIsLoading(false);
        });
    }, [searchTerm, search]);

    // useEffect(() => {
    //     setIsLoading(true);
    //     if(Object.keys(searchTerm).length > 0 || Object.keys(search).length > 0) {
    //         //issue the API Search for templates
    //         apiHandler(`${uiSelector.url}/templates/v1/find/${searchTerm.capability}/All/All/${searchTerm.search_term || 'NA'}/1/${sort || "NA"}/${uiSelector.limit * currentPage}`, "GET", null, null).then(searchedTemplatesResponse => {
    //             if(searchedTemplatesResponse.statusCode === responseHelper[200].statusCode) {
    //                 total_count = Number(searchedTemplatesResponse.total_count);
    //                 setTotalCount(total_count);
    //                 if(Number(searchedTemplatesResponse.total_count) > Number(uiSelector.limit)) {
    //                     setCanLoadMore(true);
    //                 } else {
    //                     setCanLoadMore(false);
    //                 }
    //                 if(Number(searchedTemplatesResponse.data.length) >= Number(searchedTemplatesResponse.total_count)) {
    //                     setCanLoadMore(false);
    //                 }
    //                 setSearchedTemplates(searchedTemplatesResponse.data);
    //                 setIsLoading(false);
    //             } else {
    //                 setIsLoading(false);
    //             }
    //         }).catch(searchedTemplatesError => {
    //             setIsLoading(false);
    //         });
    //     } else {
    //         setIsLoading(false);
    //     }
    // }, [sort, currentPage, searchTerm, search]);

    const changeFiltersHandler = (e) => {
        if(Object.keys(selectedFiltersData).length > 0) selectedResourceFilters = selectedFiltersData;
        if(!selectedResourceFilters[activeResource]) selectedResourceFilters[activeResource] = [];

        const afn = activeFiltersName.map(afn => encodeURIComponent(afn));
        setActiveFiltersName(afn);

        selectedFilters = selectedFilters.concat(activeFilters);
        selectedFiltersName = selectedFiltersName.concat(afn);

        selectedFilters = [...new Set(selectedFilters)];
        selectedFiltersName = [...new Set(selectedFiltersName)];
        selectedFiltersName = selectedFiltersName.filter(sfn => sfn !== "");

        if(e.target.checked) {
            selectedFilters.push(e.target.dataset.name);
            selectedFiltersName.push(encodeURIComponent(e.target.dataset.key));

            selectedResourceFilters[activeResource].push(e.target.id);
            if(activeResource === "capability") {
                selectedResourceFilters.capabilities = [];
                selectedResourceFilters.capabilities.push(e.target.dataset.name);
            }
        } else {
            const index = selectedResourceFilters[activeResource].indexOf(e.target.id);
            if(index > -1) {
                selectedResourceFilters[activeResource].splice(index, 1);
            }
            const activeIndex = selectedFilters.indexOf(e.target.dataset.name);
            const activeNameIndex = selectedFiltersName.indexOf(encodeURIComponent(e.target.dataset.key));
            if(activeIndex > -1) {
                selectedFilters.splice(activeIndex, 1);
            }
            if(activeNameIndex > -1) {
                selectedFiltersName.splice(activeNameIndex, 1);
            }
        }

        selectedFilters = [...new Set(selectedFilters)];
        selectedFiltersName = [...new Set(selectedFiltersName)];
        selectedFiltersName = selectedFiltersName.filter(sfn => sfn !== "");
        setCurrentPage(1);
        setActiveFilters(selectedFilters);
        setActiveFiltersName(selectedFiltersName);
        getFilterBasedTemplates(selectedResourceFilters, selectedFiltersName);
    }

    const changeSortHandler = (e) => {
        const sort = e.target.value;
        setSort(sort);
        const currentQuery = getQueryParams(location.search);
        currentQuery.sortBy = sort;
        navigate(generateQuery(currentQuery));
    }

    const onResetFilters = () => {
        setActiveResource(null);
        const currentQuery = getQueryParams(location.search);
        console.log(currentQuery, searchTerm);
        navigate(`?search_term=${searchTerm.search_term}&capability=${searchTerm.capability}&department=&industry=&partner=&capabilities=&aggregation=[]&index=[]`);
    }

    /**
     * @description Change resources to filter the search
     */
    const changeResourceHandler = (e) => {
        if (e.target.id === activeResource) {
            setActiveResource(null);
            return
        }
        if(e.target.id) {
            setActiveResource(e.target.id);
        }
    }

    /**
     * 
     * @param {*} e
     * @description Redirects to template detail page. 
     */
    const templateDetailHandler = (e) => {
        let ele = e.target;
        const allParents = [];
        while (ele) {
            allParents.unshift(ele);
            ele = ele.parentNode;
            if(ele.id) break;
        }
        if(ele.id && ele.id !== "root" && ele.id !== "") {
            navigate(`/t/${ele.id}`);
        }
    }

    const getFilterBasedTemplates = (filters, filterNames) => {
        const filter = {...filters};
        // if(filters.capabilities?.length === 0) {
        //     setSearch(prevSearch => {
        //         return {
        //             ...prevSearch, capability: search.capability
        //         }
        //     });
        //     console.log("search is: ", search);
        //     return;
        // } else {
        //     if(searchTerm.uid)
        //         filters.capabilities = [searchTerm.uid]
        // }
        setSelectedFiltersData(filter);
        filter.searchTerm = searchTerm.search_term;
        clearTimeout(interval);
        interval = setTimeout(() => {
            let reformUrl = `?search_term=${filter.searchTerm}`;
            if(filter.capabilities) {
                reformUrl += `&capability=${filter.capabilities}`;
            } else {
                if(query.capability) {
                    reformUrl += `&capability=${query.capability}`;
                } else {
                    reformUrl += `&capability=`;
                }
            }
            if(filter.industry) {
                reformUrl += `&industry=${filter.industry}`;
            } else {
                reformUrl += `&industry=`;
            }
            if(filter.department) {
                reformUrl += `&department=${filter.department}`;
            } else {
                reformUrl += `&department=`;
            }
            if(filter.partner) {
                reformUrl += `&partner=${filter.partner}`;
            } else {
                reformUrl += `&partner=`;
            }
            if(query.capabilities) {
                reformUrl += `&capabilities=${query.capabilities}`;
            } else {
                reformUrl += `&capabilities=`;
            }
            if(sort) {
                reformUrl += `&sortBy=${sort}`;
            } else {
                reformUrl += `&sortBy=`;
            }
            if(currentPage) {
                reformUrl += `&currentPage=${currentPage}`;
            } else {
                reformUrl += `&currentPage=`;
            }
            delete filters.search_term;
            navigate(`/search${reformUrl}&aggregation=${JSON.stringify(filters)}&index=${JSON.stringify(filterNames)}`);
            // setIsLoading(true);
            // apiHandler(`${uiSelector.url}/search/v1/filter`, "POST", filters, null).then(searchedTemplatesResponse => {
            //     if(searchedTemplatesResponse.statusCode === responseHelper[200].statusCode) {
            //         total_count = Number(searchedTemplatesResponse.total_count);
            //         setTotalCount(total_count);
            //         if(Number(searchedTemplatesResponse.total_count) > Number(uiSelector.limit)) {
            //             setCanLoadMore(true);
            //         } else {
            //             setCanLoadMore(false);
            //         }
            //         if(Number(searchedTemplatesResponse.data.length) >= Number(searchedTemplatesResponse.total_count)) {
            //             setCanLoadMore(false);
            //         }
            //         setSearchedTemplates(searchedTemplatesResponse.data);
            //         setIsLoading(false);
            //     } else {
            //         setIsLoading(false);
            //     }
            // }).catch(searchedTemplatesError => {
            //     setSearchedTemplates([]);
            //     setIsLoading(false);
            // });
        }, 500);
    };

    const changePageHandler = (event) => {
        setCurrentPage(event.selected + 1);
        const currentQuery = getQueryParams(location.search);
        currentQuery.currentPage = event.selected + 1;
        navigate(generateQuery(currentQuery));
    };

    return <section className={searchStyles["nintex-solution-second-section"]}>
        {!isLoading && <div className={searchStyles.container}>
            <div className={searchStyles["nintex-solution-sorting"]}>

                {totalCount === 0 && <h2>{NO_TEXT} {TOTAL_SEARCH_COUNT} “{decodeURIComponent(searchTerm.search_term || searchTerm.capabilities)}“</h2>}
                {totalCount > 0 && <h2>{totalCount} {TOTAL_SEARCH_COUNT} “{decodeURIComponent(searchTerm.search_term || searchTerm.capabilities)}“</h2>}

                <select className={searchStyles["nintex-solution-sort-filter"]} onChange={changeSortHandler} defaultValue={sort}>
                    <option value="">{SORT_BY_TEXT}</option>
                    {SORT_FILTERS.map((sf, key) => (
                        <option key={key} value={sf.id}>{(sort && SORT_BY_TEXT) + " " + sf.name}</option>
                    ))}
                </select>
            </div>
            <div className={searchStyles["nintex-solution-filters"]}>
                <ul className={searchStyles["nintex-solution-filters-list"]}>
                    <li className={searchStyles["nintex-solution-filter-bottom-spacing"]}>{FILTER_TEXT}</li>
                    <li className={searchStyles["nintex-solution-filter-bottom-spacing"]}>
                        <Button type="submit" className={searchStyles["nintex-solution-filter-reset"]} onClick={onResetFilters}>Reset Filters</Button>
                    </li>
                </ul>
                <div className={searchStyles["nintex-solution-filters-tab-mobile-navigation"]} onClick={changeResourceHandler}>
                    {Object.keys(resources).length > 0 && Object.keys(resources).map(fs => (
                        <Button id={fs} theme={uiSelector.theme} key={fs} component="search" className={searchStyles["nintex-solution-w3-button"] + " " + (activeResource === fs && searchStyles.active) + " " + (Object.keys(selectedFiltersData).length > 0 && selectedFiltersData[fs] && selectedFiltersData[fs].length > 0 && searchStyles.activeFilter) + " " + searchStyles["nintex-solution-button-spacing"]} dataTarget={fs}>{capitalize(fs)}</Button>
                    ))}
                </div>
                <div className={searchStyles["nintex-solution-filter-content"]}>
                    {activeResource && <div className={searchStyles["nintex-solution-panel"]}>
                        <div className={searchStyles.wrapper}>
                            {Object.keys(resources).length > 0 && resources[activeResource].map(cp => (
                                <div key={cp.name} className={searchStyles["nintex-solution-filter-item"]}><input data-parent={activeResource} data-key={cp.name} data-name={cp.id} id={cp.id} className="resources" type="checkbox" defaultChecked={activeFiltersName.length > 0 && activeFiltersName.includes(cp.name.toString())} onClick={changeFiltersHandler} />{capitalize(cp.name)}</div>
                            ))}
                        </div>
                    </div>
                    } 
                </div>
            </div>
            <div className={searchStyles["nintex-solution-cards"]} onClick={templateDetailHandler}>
                {searchedTemplates.map((cc, key) => (
                    <Card key={key} className="pointer" id={cc.friendly_url} title={cc.title} friendlyURL={cc.friendly_url} description={cc.description} image={cc.icon_image} createdBy={cc.partner} likes={cc.likes} dlCounter={cc.dl_counter} capability={cc.capability} featured={cc.is_featured} createdDate={cc.created_on} language={cc.language} showDescription={false}  />
                ))}
                {searchedTemplates.length === 0 && <div className="empty_content_search text-center"><i>{NO_TEMPLATE_RESULTS}</i></div>}
            </div>
            {(totalCount/uiSelector.limit) > 1 && 
            <div className={searchStyles["nintex-solution-pagination"]}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    activeLinkClassName={searchStyles["nintex-solution-pagination-active"]}
                    disabledClassName={searchStyles["nintex-solution-pagination-disable"]}
                    onPageChange={changePageHandler}
                    initialPage={currentPage - 1}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={totalCount/uiSelector.limit}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </div>
            }
            {/* {canLoadMore && <LoadMore clickHandler={changePageHandler} />} */}
        </div>}
        {isLoading && <div className="text-center"><Loading /></div>}
    </section>
};

export default Search;