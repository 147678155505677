/**
 * @author Varun Kashyap
*/
import React from "react";
import { CREATED_BY_TEXT, FEATURED_LABEL, VIEW_TEMPLATES } from "../../../constants/home";
import { humanDate } from "../../../helpers";
import {CARD_PUBLISHED_STATUS} from "../../../constants/cards";
import cardStyles from "./Card.module.css";

/**
 * 
 * @returns Card component for public gallery
*/
const Card = ({id, featured, title, friendlyURL, description, image, language, status, createdDate, createdBy, likes, dlCounter, capability, showDescription, className}) => {
    return <div className={className + " " + cardStyles["nintex-solution-card"]} id={friendlyURL}>
        <div className={cardStyles["nintex-solution-card-heading"]}>
        {featured === 1 && <div className={cardStyles["nintex-solution-featured"]}>{FEATURED_LABEL}</div>}
            {title.length > 49 && <h3 className={(showDescription ? cardStyles["nintex-solution-browse-cards"] : cardStyles["nintex-solution-partner-cards"] + " " + className)}>{title.substr(0, 50) + "..."}</h3>}
            {title.length < 50 && <h3 className={(showDescription ? cardStyles["nintex-solution-browse-cards"] : cardStyles["nintex-solution-partner-cards"] + " " + className)}>{title}</h3>}
            {(showDescription && description.length > 89) && <p>{description.substr(0, 90) + "..."}</p>}
            {(showDescription && description.length < 90) && <p>{description}</p>}
            {!showDescription && <><p className={cardStyles["nintex-solution-partner-content"]}>{CREATED_BY_TEXT} {createdBy || 'Nintex'} </p>
            {/* <p className={cardStyles["nintex-solution-partner-content"]}>{language?.toUpperCase()} | {status || ""} {createdDate || ""}</p></>} */}
            <p className={cardStyles["nintex-solution-partner-content"]}>{CARD_PUBLISHED_STATUS.released || ""} {humanDate(createdDate.split(" ")[0]) || ""}</p></>}
        </div>
        <div className={cardStyles["nintex-solution-card-footer"]}>
            {showDescription && <a href={`/search?search_term=&capability=${id}&industry=&department=&partner=&capabilities=${title.toLowerCase()}&sortBy=&aggregation=${JSON.stringify({})}&index=${JSON.stringify([])}&currentPage=${1}`}>{VIEW_TEMPLATES} &#8250;&#8250;</a>}
            {!showDescription && <><ul className={cardStyles["nintex-solution-card-icons"]}>
                <li>{capability || ""}</li>
                {likes && <li><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" /></svg>{likes || 0}</li>}
                <li><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z" clipRule="evenodd" /></svg>{dlCounter || 0}</li>
            </ul></>}
            <img src={`${image}`} alt={title.split(" ")?.[0]} />
        </div>
    </div>
};  

export default Card;