// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputField_input__6kzLp {\n    width: 100%;\n    border: 1px solid #D4D4D4;\n    height: 30px;\n    outline: none;\n    padding: 0 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/InputField/InputField.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,eAAe;AACnB","sourcesContent":[".input {\n    width: 100%;\n    border: 1px solid #D4D4D4;\n    height: 30px;\n    outline: none;\n    padding: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "InputField_input__6kzLp"
};
export default ___CSS_LOADER_EXPORT___;
